import React, { useContext, useEffect, useState } from 'react'

import { Col, Row, Form } from '@themesberg/react-bootstrap'
import { CounterWidget } from '../../components/Widgets'
import IncomingTransactions from '../../assets/icons/incoming_transactions.png'
import SuccessfulTransactions from '../../assets/icons/successful_transactions.svg'
import customers from '../../assets/icons/customers.png'
import { InsuranceContext } from '../../context/InsuranceContext'
import TransactionsTable, { exportToCSV } from './TransactionsTable'
import { DateRangePicker } from 'rsuite'

export default () => {
  const {
    parsedUser,
    dashboardStats,
    dashboardStatsLoading,
    fetchDashboardStats,
    fetchTransactions,
    settingRecords,
    transactions,
    transactionsLoading,
  } = useContext(InsuranceContext)
  const [dateRange, setDateRange] = useState([null, null])

  const formatSelectedDate = (inputDateString) => {
    const inputDate = new Date(inputDateString)
    const year = inputDate.getFullYear()
    const month = String(inputDate.getMonth() + 1).padStart(2, '0')
    const day = String(inputDate.getDate()).padStart(2, '0')
    const formattedDate = `${year}-${month}-${day}`
    return formattedDate
  }

  useEffect(() => {
    if (parsedUser === null) {
      window.location.href = '/'
    }

    fetchDashboardStats()
    fetchTransactions()
  }, [])

  useEffect(() => {
    if (!dateRange) return

    const newArray = dateRange.filter((item) => item !== null)
    if (newArray.length > 1) {
      let start_date = formatSelectedDate(dateRange[0])
      let end_date = formatSelectedDate(dateRange[1])
      let query = `?start_date=${start_date}&end_date=${end_date}`

      fetchTransactions(query)
    }
  }, [dateRange])

  // console.og
  return (
    <>
      <Row className="">
        <Col xs={2} xl={3}>
          <h4>Overview </h4>
        </Col>
      </Row>

      <Row className="">
        <Col xs={12} sm={6} xl={4} className="mb-4">
          <CounterWidget
            category="Total Transactions"
            title={dashboardStatsLoading ? 'Loading' : dashboardStats?.totalTransaction}
            period=""
            percentage={0}
            icon={IncomingTransactions}
            iconColor="shape-secondary"
          />
        </Col>

        <Col xs={12} sm={6} xl={4} className="mb-4">
          <CounterWidget
            category="Successful Transactions"
            title={dashboardStatsLoading ? 'Loading...' : dashboardStats?.approved}
            period="Feb 1 - Apr 1"
            percentage={28.4}
            icon={SuccessfulTransactions}
            iconColor="shape-tertiary"
          />
        </Col>

        <Col xs={12} sm={6} xl={4} className="mb-4">
          <CounterWidget
            category="Abandoned"
            title={dashboardStatsLoading ? 'Loading...' : dashboardStats?.abandoned}
            period="Feb 1 - Apr 1"
            percentage={28.4}
            icon={customers}
            iconColor="shape-tertiary"
          />
        </Col>
      </Row>

      <section className="d-flex justify-content-between pb-3">
        <Form.Group className="">
          <DateRangePicker value={dateRange} onChange={(dates) => setDateRange(dates)} />
        </Form.Group>

        <div>
          {transactions.data && !transactionsLoading && (
            <button
              onClick={() => exportToCSV(transactions.data)}
              className="btn btn-outline-primary"
            >
              Export to .csv
            </button>
          )}
        </div>
      </section>
      <TransactionsTable searchTerm="" />
    </>
  )
}
