import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InsuranceContext } from '../context/InsuranceContext';
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { Nav, Image, Navbar, Dropdown, Container } from '@themesberg/react-bootstrap';
import Bonnie from "../assets/img/team/bonnie.png";


export default (props) => {
  const { logoutUser } = useContext(InsuranceContext);
  let user = JSON.parse(sessionStorage.getItem('user'));

  const logOut = () => {
    logoutUser()
  }

  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
          </div>
          <Nav className="align-items-center">
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <Image src={Bonnie} className="user-avatar md-avatar rounded-circle" />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                <Dropdown.Item className="fw-bold" >
                  {user?.email}
                </Dropdown.Item>

                <Dropdown.Item className="fw-bold" >
                  <span onClick={logOut} >
                    <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Logout</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};
