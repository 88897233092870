
import React from 'react';

export default (props) => {
  const { show } = props;
  return (
    <div className={`preloader bg-soft flex-column justify-content-center align-items-center ${show ? "" : "show"}`}>
    </div>
  );
};
