import React, { useContext, useEffect, useState } from "react";
import { DateRangePicker } from 'rsuite';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Card, Form } from '@themesberg/react-bootstrap';

import TransactionsTable, { exportToCSV } from "./TransactionsTable";
import { InsuranceContext } from '../../context/InsuranceContext';

export default () => {
  const { parsedUser, transactions, fetchTransactions, transactionsLoading, } = useContext(InsuranceContext);

  const [searchTerm, setSearchTerm] = useState('');
  const [dateRange, setDateRange] = useState([null, null]);

  const handleSearchChange = event => {
    setSearchTerm(event.target.value);
  };

  const formatSelectedDate = (inputDateString) => {
    const inputDate = new Date(inputDateString);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, '0');
    const day = String(inputDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  useEffect(() => {
    if (parsedUser === null) {
      window.location.href = '/';
    }

    fetchTransactions();
  }, []);


  useEffect(() => {
    if (!dateRange) return

    const newArray = dateRange.filter(item => item !== null);
    if (newArray.length > 1) {
      let start_date = formatSelectedDate(dateRange[0]);
      let end_date = formatSelectedDate(dateRange[1]);
      let query = `?start_date=${start_date}&end_date=${end_date}`;

      fetchTransactions(query);
    }
  }, [dateRange]);


  return (
    <>

      <h5 className="mb-4 mt-4">New Request</h5>

      <Row>

        <Col xs={12} xl={9}>
          <Row>
            {/*
            <Col sm={2} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Select id="state" defaultValue="0">
                  <option value="0">Past 30 Days</option>
                  <option value="1">Past 60 Days</option>
                  <option value="2">Past 90 Days</option>
                </Form.Select>
              </Form.Group>
            </Col> */}

            <Col sm={3} className="mb-3">
              <Form.Group className="mb-2">
                <DateRangePicker
                  format="yyyy-MM-dd"
                  value={dateRange}
                  onChange={dates => setDateRange(dates)}
                />
              </Form.Group>
            </Col>

            <Col sm={4}>
              <div className="d-flex align-items-center">
                <Form className="navbar-search">
                  <div className="search-bar form-control d-flex align-items-center">
                    <FontAwesomeIcon icon={faSearch} />
                    <input type="text" className="border-0 focus-ring focus-ring-light" value={searchTerm} onChange={handleSearchChange} placeholder="Search..." />
                  </div>
                </Form>
              </div>
            </Col>

            <Col sm={4}>
              {transactions.data && !transactionsLoading && (
                <button
                  onClick={() => exportToCSV(transactions.data)}
                  className="btn btn-outline-primary"
                >
                  Export to .csv
                </button>
              )}
            </Col>
          </Row>
        </Col>


        <Col xs={12} xl={12}>
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>

              <TransactionsTable searchTerm={searchTerm} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
