import React, { useState, useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Routes } from '../routes'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// pages
import SubscriptionDetails from './dashboard/SubscriptionDetails'
import Upgrade from './dashboard/Upgrade'
import DashboardOverview from './dashboard/DashboardOverview'
import Transactions from './dashboard/Transactions'
import Settings from './dashboard/NewRequest'
import Signin from './authentication/Signin'
import Signup from './authentication/Signup'
import NotFoundPage from './authentication/NotFound'
import DocsOverview from './dashboard/Settings'
import InitiateTransaction from './dashboard/InitiateTransaction'

// components
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import Preloader from '../components/Preloader'

import { InsuranceProvider } from '../context/InsuranceContext'

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000)
    return () => clearTimeout(timer)
  }, [])

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {' '}
          <Preloader show={loaded ? false : true} /> <Component {...props} />{' '}
        </>
      )}
    />
  )
}

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000)
    return () => clearTimeout(timer)
  }, [])

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Preloader show={loaded ? false : true} />
          <Sidebar />

          <main className="content">
            <Navbar />
            <Component {...props} />
          </main>
        </>
      )}
    />
  )
}

export default () => (
  <InsuranceProvider>
    <Switch>
      <RouteWithLoader exact path="/" component={Signin} />
      <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
      <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
      <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />

      {/* pages */}
      <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={DashboardOverview} />
      <RouteWithSidebar exact path={Routes.Upgrade.path} component={Upgrade} />
      <RouteWithSidebar exact path={Routes.Transactions.path} component={Transactions} />
      <RouteWithSidebar exact path={Routes.Settings.path} component={Settings} />
      <RouteWithSidebar
        exact
        path={Routes.InitiateTransaction.path}
        component={InitiateTransaction}
      />
      {/* <RouteWithSidebar exact path={Routes.BootstrapTables.path} component={BootstrapTables} /> */}
      <RouteWithSidebar
        exact
        path={Routes.SubscriptionDetails.path}
        component={SubscriptionDetails}
      />
      <RouteWithSidebar exact path={Routes.DocsOverview.path} component={DocsOverview} />

      <Redirect to={Routes.NotFound.path} />
    </Switch>
    <ToastContainer />
  </InsuranceProvider>
)
