import React, { useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons';
import { useLocation, useHistory } from 'react-router-dom';
import { Badge, Col, Card, Row, Nav, Tab } from '@themesberg/react-bootstrap';
import { Table } from '@themesberg/react-bootstrap';
import { InsuranceContext } from '../../context/InsuranceContext';
import { formatDate, formatNaira, statusCheckColor } from '../../utils/utils';

export default () => {
    const history = useHistory();
    const search = useLocation().search
    const searchParams = new URLSearchParams(search)
    const { parsedUser,
        declineProcessLoading, approveProcessLoading, processTransaction, processTransactionLoading,
        singleTransaction, fetchSingleTransaction, singleTransactionLoading, settingRecords, fetchSettingRecords, } = useContext(InsuranceContext);
    const queryId = searchParams.get('id').toString();
    let user = JSON.parse(sessionStorage.getItem('user'));

    const currentState = singleTransaction?.data?.customers?.currentState
    const isTwoInstallment = singleTransaction?.data?.paymentType == "TWICE"

    const approve = async () => {
        let params = {
            "status": "approved",
            "transId": queryId
        };
        await processTransaction(params)
    }

    const decline = async () => {
        let params = {
            "status": "declined",
            "transId": queryId
        };
        await processTransaction(params)
    }

    useEffect(() => {
        if (parsedUser === null) {
            window.location.href = '/';
        }

        fetchSingleTransaction(queryId);
        fetchSettingRecords();
    }, []);


    return (
        <>
            <h5 className="mb-4 mt-4"> <FontAwesomeIcon icon={faArrowAltCircleLeft} className="me-2" onClick={() => history.push('/request')} />Request Details</h5>
            <p className="mb-lg-5">Customer’s transaction details</p>
            <Row className="mt-lg-5 mt-4 d-flex">
                <Col xl={9}>
                    <Card>
                        {singleTransactionLoading ? <h2 className="text-center mt-2 mb-2">Loading...</h2> :
                            <Tab.Container defaultActiveKey="InsuredDetails">
                                <Nav className="flex-column flex-sm-row">
                                    <Nav.Item>
                                        <Nav.Link eventKey="InsuredDetails" className="mb-sm-3 mb-md-0 fw-bold">
                                            Insured Details
                                        </Nav.Link>
                                    </Nav.Item>
                                    {(currentState == "premium_display_screen" || currentState == "payment_made") &&
                                        <Nav.Item>
                                            <Nav.Link eventKey="RepaymentPlan" className="mb-sm-3 mb-md-0 fw-bold">
                                                Repayment Plan
                                            </Nav.Link>
                                        </Nav.Item>
                                    }
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="InsuredDetails" className="">
                                        <Table className="comparison-table table-striped">
                                            <tbody>
                                                <Section title='Full Name'>{singleTransaction?.data?.customers.firstName || 'N/A'}&nbsp;{singleTransaction?.data?.customers.lastName}</Section>
                                                <Section title='Phone Number'>{singleTransaction?.data?.customers?.phoneNumber || 'N/A'}</Section>
                                                <Section title='Email'>{singleTransaction?.data?.customers?.email || 'N/A'}</Section>
                                                <Section title='Insurance Company'>{singleTransaction?.data?.merchantName}</Section>
                                                <Section title='Plate Number'>{singleTransaction?.data?.customers?.plateNumber || 'N/A'}</Section>
                                                <Section title='Vehicle'>{singleTransaction?.data?.customers?.make || 'N/A'}&nbsp;{singleTransaction?.data?.customers?.model || 'N/A'}&nbsp;{singleTransaction?.data?.customers?.year || 'N/A'}</Section>
                                                <Section title='Previous Cover Period'>{singleTransaction?.data?.customers?.previousCoverPeriod || 'N/A'}</Section>
                                                <Section title='Premium'>{formatNaira(singleTransaction?.data?.premium)}</Section>
                                                <Section title='Sum Insured'>{formatNaira(+singleTransaction?.data?.customers?.sumInsured)}</Section>
                                                <Section title='Installment Type'>{isTwoInstallment ? "Two Installments" : "Four Installments"}</Section>
                                                <Section title='Transaction ID'>{singleTransaction?.data?.transId}</Section>
                                                <Section title='Transaction Date'>{formatDate(singleTransaction?.data?.created_at)}</Section>
                                                <Section title='Transaction Status'>
                                                    <>
                                                        <Badge className="badge-lg text-uppercase fw-bold" bg={`${statusCheckColor(singleTransaction?.data?.status)}`}>{singleTransaction?.data?.status}</Badge>
                                                        {user?.role == "admin" && <Badge className="badge-lg text-uppercase bg-secondary">{currentState?.replaceAll("_", " ")}</Badge>}
                                                    </>
                                                </Section>

                                                {/* {(user?.role === "partner" && settingRecords?.env === "test" && singleTransaction?.data?.customers?.status == "pending") || (user?.role === "admin" && singleTransaction?.data?.customers?.status == "pending") ?
                                                    <tr>
                                                        <td className="border-0">
                                                            <Button variant="primary" classerName="m-0 approve-btn" style={{ marginRight: "1em !important" }} onClick={approve}>{approveProcessLoading ? 'Loading...' : 'Approve'}</Button>
                                                            &nbsp; &nbsp; &nbsp;
                                                            <Button variant="danger" className="m-0" onClick={decline}>{declineProcessLoading ? 'Loading...' : 'Decline'} </Button>
                                                        </td>
                                                        <td className="border-0"></td>
                                                    </tr>
                                                    : <></>} */}
                                            </tbody>
                                        </Table>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="RepaymentPlan" className="">
                                        <Table className="comparison-table table-striped">
                                            <tbody>
                                                <Section title='Merchant Name'>{singleTransaction?.data?.merchantName || 'N/A'}</Section>
                                                <Section title='Loan Amount'>{formatNaira(singleTransaction?.data?.[isTwoInstallment ? "premium" : "totalAmount"]) || 'N/A'}</Section>
                                                {isTwoInstallment ?
                                                    <>
                                                        <Section title='First Payment Plan'>{formatNaira(singleTransaction?.data?.altPaymentOne) || 'N/A'}</Section>
                                                        <Section title='Second Payment Plan'><span className="see-more-bold">{formatNaira(singleTransaction?.data?.altPaymentTwo) || 'N/A'}</span> due on {singleTransaction?.data?.altPaymentDate || 'N/A'}</Section>
                                                    </> :
                                                    <>
                                                        <Section title='First Payment Plan'>{formatNaira(singleTransaction?.data?.firstPayment) || 'N/A'}</Section>
                                                        <Section title='Second Payment Plan'><span className="see-more-bold">{formatNaira(singleTransaction?.data?.secondPayment) || 'N/A'}</span> due on {singleTransaction?.data?.secondPaymentDate || 'N/A'}</Section>
                                                        <Section title='Third Payment Plan'><span className="see-more-bold">{formatNaira(singleTransaction?.data?.thirdPayment) || 'N/A'} </span>due on {singleTransaction?.data?.thirdPaymentDate || 'N/A'}</Section>
                                                        <Section title='Fourth Payment Plan'><span className="see-more-bold">{formatNaira(singleTransaction?.data?.fourthPayment) || 'N/A'}</span> due on {singleTransaction?.data?.fourthPaymentDate || 'N/A'}</Section>
                                                    </>}
                                                <Section title='Transaction Status'>
                                                    <>
                                                        <Badge className="badge-lg text-uppercase fw-bold" bg={`${statusCheckColor(singleTransaction?.data?.status)}`}>{singleTransaction?.data?.status}</Badge>
                                                        {user?.role == "admin" && <Badge className="badge-lg text-uppercase bg-secondary">{currentState?.replaceAll("_", " ")}</Badge>}
                                                    </>
                                                </Section>
                                                {/* <tr>
                                                    <td className="border-0">
                                                        <Button variant="primary" classerName="m-0 approve-btn" style={{ marginRight: "1em !important" }} onClick={approve}>{approveProcessLoading ? 'Loading...' : 'Approve'}</Button>
                                                        &nbsp; &nbsp; &nbsp;
                                                        <Button variant="danger" className="m-0" onClick={decline}>{declineProcessLoading ? 'Loading...' : 'Decline'} </Button>
                                                    </td>
                                                    <td className="border-0">

                                                    </td>
                                                </tr> */}
                                                {/* settingRecords?.data? */}


                                                {/* {(user?.role === "partner" && settingRecords?.env === "test" && singleTransaction?.data?.customers?.status == "pending") || (user?.role === "admin" && singleTransaction?.data?.customers?.status == "pending") ?
                                                    <tr>
                                                        <td className="border-0">
                                                            <Button variant="primary" classerName="m-0 approve-btn" style={{ marginRight: "1em !important" }} onClick={approve}>{approveProcessLoading ? 'Loading...' : 'Approve'}</Button>
                                                            &nbsp; &nbsp; &nbsp;
                                                            <Button variant="danger" className="m-0" onClick={decline}>{declineProcessLoading ? 'Loading...' : 'Decline'} </Button>
                                                        </td>
                                                        <td className="border-0"></td>
                                                    </tr>
                                                    : <></>} */}
                                            </tbody>
                                        </Table>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        }

                    </Card>
                </Col>


            </Row>
        </>
    );
};

function Section({ title, children }) {
    return (
        <tr>
            <td className="border-0">{title}</td>
            <td className="border-0">{children}</td>
        </tr>
    );
}
