import { Row, Col, Card, Table } from '@themesberg/react-bootstrap'
import axios from 'axios'
// import { Modal } from 'bootstrap'
import { useContext, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Modal } from '@themesberg/react-bootstrap'
import { InsuranceContext } from '../../context/InsuranceContext'

export default function InitiateTransaction() {
  const { settingRecords, fetchSettingRecords } = useContext(InsuranceContext)

  let user = sessionStorage.getItem('user')
  let parsedUser = JSON.parse(user)
  let { token } = parsedUser

  const plateNumberInput = useRef()
  const [loading, setLoading] = useState(false)

  const [showModal, setShowModal] = useState(false)
  const [carDetails, setCarDetails] = useState({})

  const closeModal = () => {
    setShowModal(false)
  }

  const reviewInitiation = async () => {
    const plateNumber = plateNumberInput.current.value

    if (plateNumber.length > 5) {
      setLoading(true)
      const { env } = settingRecords
      const payload = { env, plateNumber }

      await axios
        .post(`${process.env.REACT_APP_API_URL}initiate-customer-transaction`, payload, {
          headers: { token },
        })
        .then((response) => {
          const { records } = response?.data

          if (!records?.email) {
            toast.error('Records not found')
          } else {
            setShowModal(true)
            setCarDetails(records)
          }
        })
        .catch((error) => {
          toast.error('Error encountered')
        })
      setLoading(false)
    } else toast.warning('Enter a valid Plate Number')
  }

  const submitInitiation = async () => {
    setLoading(true)
    const { email, transId } = carDetails
    const { env } = settingRecords
    const payload = { email, transId, env }

    await axios
      .post(`${process.env.REACT_APP_API_URL}send-customer-email`, payload, {
        headers: { token },
      })
      .then((response) => {
        setShowModal(false)
        plateNumberInput.current.value = ''
        toast.success('Transaction Initiated Successfully')
      })
      .catch((error) => {
        toast.error('Error encountered')
      })
    setLoading(false)
  }

  useEffect(() => {
    fetchSettingRecords()
  }, [])

  return (
    <>
      <h5 className="mb-4 mt-4">Initiate Transaction</h5>

      <Row>
        <Col xs={12} className="">
          <Card>
            <Card.Body>
              <Col md={6} className="mb-3 mx-auto text-center">
                <label className="row">
                  <div className="pb-3">Enter plate number</div>
                  <input
                    type="text"
                    ref={plateNumberInput}
                    className="form-control w-100 text-center fs-4 fw-bold text-black mb-5"
                    style={{ letterSpacing: '0.5em' }}
                  />
                </label>

                <button
                  variant="primary"
                  disabled={loading}
                  onClick={reviewInitiation}
                  className="btn btn-primary w-50"
                >
                  {loading ? 'Please wait' : 'Proceed'}
                </button>
              </Col>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal show={showModal} onHide={closeModal} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Customer Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <tbody>
              <TableRow title="Name">
                {carDetails.firstName} {carDetails.lastName}
              </TableRow>
              <TableRow title="Phone Number">{carDetails.phoneNumber}</TableRow>
              <TableRow title="Email">{carDetails.email}</TableRow>
              <TableRow title="Vehicle Details">
                {carDetails.make} - {carDetails.model} ({carDetails.color})
              </TableRow>
              <TableRow title="Make Year">{carDetails.year}</TableRow>
              <TableRow title="Vehicle Type">{carDetails.vehicleType}</TableRow>
              <TableRow title="Plate Number">{carDetails.plateNumber}</TableRow>
              <TableRow title="Engine Number">{carDetails.engineNumber}</TableRow>
              <TableRow title="Vehicle Value">
                ₦ {new Intl.NumberFormat().format(carDetails.value)}
              </TableRow>
              <TableRow title="Sum Insured">
                ₦ {new Intl.NumberFormat().format(carDetails.sumInsured)}
              </TableRow>
            </tbody>
          </Table>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger" className="w-25" onClick={closeModal}>
            Cancel
          </Button>
          <Button variant="primary" className="w-50" onClick={submitInitiation}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

function TableRow({ title, children }) {
  return (
    <tr>
      <td>{title}</td>
      <td>{children}</td>
    </tr>
  )
}
