
import React, { useContext, useEffect, useState } from "react";
import axios from 'axios';
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Button, FormCheck, InputGroup } from '@themesberg/react-bootstrap';
import { useHistory } from "react-router-dom";
import { InsuranceContext } from "../../context/InsuranceContext";

export default () => {
  const { parsedUser, loginUser } = useContext(InsuranceContext);
  const [userDetailsLoading, setUserDetailsLoading] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const history = useHistory();

  const validateForm = () => {
    const newErrors = {};

    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!emailAddress.trim()) {
      newErrors.emailAddress = 'Email address is required';
    } else if (!emailAddress.match(regex)) {
      newErrors.emailAddress = 'Email address is not valid';
    }

    if (!password.trim()) {
      newErrors.password = 'Password is required';
    }

    return newErrors;
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      // Perform login request using Axios
      setUserDetailsLoading(true);

      let params = {
        "email": emailAddress,
        "password": password
      };

      await axios.post(`${process.env.REACT_APP_API_URL}login`, params)
        .then((response) => {
          if (response?.data?.isError) {
            toast.error(response.data.message);
          } else {
            loginUser(response.data);
            sessionStorage.setItem('user', JSON.stringify(response.data));
            history.push("/dashboard/overview")
          }
        }).catch((error) => {
          toast.error(error.response.data.message);
        })
      setUserDetailsLoading(false);
    } catch (error) {
      setUserDetailsLoading(false);
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    if (parsedUser !== null) {
      history.push("/dashboard/overview");
    }
  }, []);


  return (
    <main>
      <section className="d-flex mt-5">
        <div className="container mt-5">
          <div className="row mt-5">
            <div className="col-6 col-lg-6 background signin-side"> </div>
            <div className="col-6 col-lg-6">
              <Row className="justify-content-center form-bg-image mt-4">
                <Col xs={12} className="d-flex align-items-center justify-content-center">
                  <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                    <div className="text-center text-md-center mb-4 mt-md-0">
                      <img src="https://octamile.com/assets/img/octamile_logo_blue.png" className="img-fluid" height={60} width={150} />
                    </div>
                    <Form className="mt-4">
                      <Form.Group id="email" className="mb-4">
                        <Form.Label>Email</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faEnvelope} />
                          </InputGroup.Text>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="example@company.com"
                            value={emailAddress}
                            onChange={(e) => setEmailAddress(e.target.value)}
                          />

                        </InputGroup>
                        {errors.emailAddress && <span className="text-danger">{errors.emailAddress}</span>}
                      </Form.Group>
                      <Form.Group>
                        <Form.Group id="password" className="mb-4">
                          <Form.Label>Password</Form.Label>

                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faUnlockAlt} />
                            </InputGroup.Text>
                            <input
                              type="password"
                              className="form-control"
                              placeholder="Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </InputGroup>
                          {errors.password && <span className="text-danger">{errors.password}</span>}
                        </Form.Group>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                          <Form.Check type="checkbox">
                            <FormCheck.Input id="defaultCheck5" className="me-2" />
                            <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Remember me</FormCheck.Label>
                          </Form.Check>
                        </div>
                      </Form.Group>
                      <Button variant="primary" type="submit" className="w-100" onClick={onSubmit}>
                        {userDetailsLoading ? 'Loading...' : ' Sign in'}
                      </Button>
                    </Form>

                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
