export const Routes = {
  Presentation: { path: "/" },
  Transactions: { path: "/subscription" },
  SubscriptionDetails: { path: "/subscription/details" },
  DashboardOverview: { path: "/dashboard/overview" },
  Settings: { path: "/request" },
  Upgrade: { path: "/request/details" },
  BootstrapTables: { path: "/tables/bootstrap-tables" },
  Billing: { path: "/examples/billing" },
  Invoice: { path: "/examples/invoice" },
  Signin: { path: "/sign-in" },
  Signup: { path: "/examples/sign-up" },
  ForgotPassword: { path: "/examples/forgot-password" },
  ResetPassword: { path: "/examples/reset-password" },
  Lock: { path: "/examples/lock" },
  NotFound: { path: "/examples/404" },
  ServerError: { path: "/examples/500" },
  DocsOverview: { path: "/settings" },
  InitiateTransaction: { path: "/initiate-transaction" },
};
