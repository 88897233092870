import React, { useState, createContext } from "react";
import axios from 'axios';
import { toast } from "react-toastify";

let user = sessionStorage.getItem('user');
let parsedUser = JSON.parse(user);

export const InsuranceContext = createContext();
export const InsuranceProvider = props => {

    const [subscriptionsLoading, setSubscriptionsLoading] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);

    const [singleTransaction, setSingleTransaction] = useState({});
    const [singleTransactionLoading, setSingleTransactionLoading] = useState(false);

    const [transactions, setTransactions] = useState([]);
    const [transactionsLoading, setTransactionsLoading] = useState(false);

    const [dashboardStats, setDashboardStats] = useState({});
    const [dashboardStatsLoading, setDashboardStatsLoading] = useState(false);
    const [approveProcessLoading, setApproveProcessLoading] = useState(false);
    const [declineProcessLoading, setDeclineProcessLoading] = useState(false);

    const [settingRecords, setSettingRecords] = useState(null);
    const [fetchSettingRecordsLoading, setFetchSettingRecordsLoading] = useState(false);
    const [addRecordsLoading, setAddRecordsLoading] = useState(false);

    const [userDetails, setUserDetails] = useState(null);
    const [userDetailsLoading, setUserDetailsLoading] = useState(false);
    const [switchEnvironmentLoading, setSwitchEnvironmentLoading] = useState(false);

    const redirectExpiredToken = (error) => {
        if (error?.code !== 'ERR_NETWORK') {
            if (error.response?.status == 401) {
                window.location.href = '/';
            }
        }
    }


    const logoutUser = () => {
        // Implement logout logic if needed
        sessionStorage.clear();
        setUserDetails(null);
        window.location.href = '/';
    };

    /// Login User Subscriptions
    // /v1/admin/login
    const loginUser = (params) => {
        setUserDetails(params);
    }


    /// Fetch Subscriptions
    // /api/v1/admin/list-subscription
    const fetchSubscriptions = () => {
        setSubscriptionsLoading(true);
        let { token } = parsedUser;
        axios.get(`${process.env.REACT_APP_API_URL}list-subscription`, { headers: { token } })
            .then(function (response) {
                setSubscriptions(response.data);
                setSubscriptionsLoading(false);

            })
            .catch(function (error) {
                console.error({ error });
                redirectExpiredToken(error);
                setSubscriptionsLoading(false);
            });
    }


    /// Fetch Transactions
    // /api/v1/admin/list-transaction/
    const fetchTransactions = (params) => {
        let query = '';
        if (typeof params !== 'undefined') {
            query = params
        }
        setTransactionsLoading(true);
        let { token } = parsedUser
        axios.get(`${process.env.REACT_APP_API_URL}list-transaction${query}`, { headers: { token } })
            .then(function (response) {
                if (response.data.data === null) {
                    setTransactions([]);
                } else {
                    setTransactions(response.data);
                }

                setTransactionsLoading(false);

            })
            .catch(function (error) {
                console.error({ error });
                setTransactionsLoading(false);
                setTransactions([])
                redirectExpiredToken(error);

            });
    }


    /// Fetch Transactions
    // /api/v1/admin/list-transaction/:id
    const fetchSingleTransaction = (params) => {
        setSingleTransactionLoading(true);
        let { token } = parsedUser
        axios.get(`${process.env.REACT_APP_API_URL}list-transaction/${params}`, { headers: { token } })
            .then(function (response) {
                setSingleTransaction(response.data);
                setSingleTransactionLoading(false);

            })
            .catch(function (error) {
                setSingleTransactionLoading(false);
                redirectExpiredToken(error);

            });
    }

    // Process transactions
    // /api/v1/admin/process-transaction
    const processTransaction = (params) => {
        let { token } = parsedUser;

        if (params.status === "declined") {
            setDeclineProcessLoading(true);
        }
        if (params.status === "approved") {
            setApproveProcessLoading(true)
        }

        axios.post(`${process.env.REACT_APP_API_URL}process-transaction`, params, { headers: { token } })
            .then(function (response) {
                toast.success('Successfully processed transaction');
                if (params.status === "declined") {
                    setDeclineProcessLoading(false);
                }
                if (params.status === "approved") {
                    setApproveProcessLoading(false)
                }
                setTimeout(function () {
                    window.location.reload(false);
                }, 2500);

            })
            .catch(function (error) {
                console.error({ error });
                redirectExpiredToken(error);

                // setProcessTransactionLoading(false);
                toast.error('Error processing transaction');
                if (params.status === "declined") {
                    setDeclineProcessLoading(false);
                }
                if (params.status === "approved") {
                    setApproveProcessLoading(false)
                }
            });
    }

    // Fetch Dashboard Stats
    // /api/v1/admin/get-stats
    const fetchDashboardStats = () => {
        let { token } = parsedUser
        setDashboardStatsLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}get-stats`, { headers: { token } })
            .then(function (response) {
                setDashboardStats(response.data);
                setDashboardStatsLoading(false);
            })
            .catch(function (error) {
                redirectExpiredToken(error);
                setDashboardStatsLoading(false);
            });
    }


    // Fetch Setting Records 
    // /api/v1/admin/get-setting-records
    const fetchSettingRecords = (params) => {
        setFetchSettingRecordsLoading(true);
        let { token } = parsedUser
        axios.get(`${process.env.REACT_APP_API_URL}get-setting-records`, { headers: { token } })
            .then(function (response) {
                setSettingRecords(response.data);
                setFetchSettingRecordsLoading(false);
            })
            .catch(function (error) {
                console.error({ error });
                redirectExpiredToken(error);
                setFetchSettingRecordsLoading(false);
            });
    }


    // Add Settings Records 
    // /api/v1/admin/create-setting-records
    const addSettingRecords = (params) => {
        setAddRecordsLoading(true);
        let { token } = parsedUser
        axios.post(`${process.env.REACT_APP_API_URL}create-setting-records`, params, { headers: { token } })
            .then(function (response) {
                setAddRecordsLoading(false);
                toast.success(response.data.data)
            })
            .catch(function (error) {
                console.error({ error });
                redirectExpiredToken(error);
                setAddRecordsLoading(false);
                toast.error('Error encountered')
            });
    }


    // Add Settings Records 
    // /api/v1/admin/create-setting-records
    const switchEnvironment = (params) => {
        setSwitchEnvironmentLoading(true);
        let { token } = parsedUser
        axios.post(`${process.env.REACT_APP_API_URL}switch-env`, params, { headers: { token } })
            .then(function (response) {
                setSwitchEnvironmentLoading(false);
                toast.success(response.data.message)
                setTimeout(function () {
                    window.location.reload(false);
                }, 2500);
            })
            .catch(function (error) {
                console.error({ error });
                redirectExpiredToken(error);
                setSwitchEnvironmentLoading(false);
                toast.error('Error encountered')
            });
    }


    return (
        <InsuranceContext.Provider
            value={{
                subscriptions,
                subscriptionsLoading,
                fetchSubscriptions,

                transactions,
                transactionsLoading,
                fetchTransactions,

                singleTransaction,
                singleTransactionLoading,
                fetchSingleTransaction,

                dashboardStats,
                dashboardStatsLoading,
                fetchDashboardStats,

                processTransaction,
                declineProcessLoading,
                approveProcessLoading,

                settingRecords,
                fetchSettingRecords,
                fetchSettingRecordsLoading,

                addRecordsLoading,
                addSettingRecords,

                userDetails,
                userDetailsLoading,
                loginUser,
                logoutUser,
                parsedUser,
                switchEnvironment,
                switchEnvironmentLoading

            }}>
            {props.children}
        </InsuranceContext.Provider>
    )
}

export default InsuranceProvider;