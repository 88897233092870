import React, { useEffect, useContext } from "react";
import DataTable from 'react-data-table-component';
import { Row } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { InsuranceContext } from '../../context/InsuranceContext';
import { formatDate } from '../../utils/utils'

export default () => {

  const {parsedUser, subscriptions,
    subscriptionsLoading,
    fetchSubscriptions, } = useContext(InsuranceContext);


    function statusCheck(value) {
      switch (value) {
        case 'active':
          return "text-success text-uppercase";
        case 'cancelled':
          return "text-danger text-uppercase";
        default:
          return "text-seondary text-uppercase";
      }
    }

  const columns = [
    {
      name: 'Customer’s Name',
      selector: (row) => <>{row.customer?.first_name || 'Not Available'}&nbsp;{row.customer.last_name}</>,

    },
    {
      name: 'Type',
      selector: (row) => <span className="text-uppercase">{row.authorization.card_type}</span>,

    },
    {
      name: 'Card Details',
      selector: (row) => <>*** **** **** {row.authorization.last4}</>,

    }, {
      name: 'Expiry Date',
      selector: (row) => <>{row.authorization.exp_month}/{row.authorization.exp_year.slice(-2)}</>,

    },
    {
      name: 'Status',
      selector: (row) =>  <span className={statusCheck(row.status)}>{row.status}</span>,

    },
    {
      name: 'Next Payment Date',
      selector: (row) => <>{formatDate(row.next_payment_date)}</>,

    },

    {
      name: 'Action',
      selector: (row) => <Link className="fw-bold" to={`/subscription/details?id=${row.id}`}>See more</Link>,

    },
  ];

  useEffect(() => {
    if (parsedUser === null) {
      window.location.href = '/';
    }
    fetchSubscriptions();
  }, []);


  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h4>Transaction History</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
        </div>
      </div>

      <Row className="justify-content-md-center px-2">
        {subscriptionsLoading ? <h1 className="text-center">Loading...</h1> : <DataTable
          columns={columns}
          data={subscriptions?.data?.data}
          pagination
          paginationPerPage={10} // Number of rows per page
          paginationRowsPerPageOptions={[10, 20,30,40,50]} // Options for rows per page
        />}
      </Row>
    </>
  );
};
