
import React, { useContext, useEffect, useState, } from 'react';
import { Breadcrumb, Button, Row, Col, Card, Form } from '@themesberg/react-bootstrap';
import { InsuranceContext } from '../../context/InsuranceContext';

export default () => {

  const { switchEnvironment, switchEnvironmentLoading, parsedUser, addRecordsLoading, addSettingRecords, settingRecords,
    fetchSettingRecords, fetchSettingRecordsLoading } = useContext(InsuranceContext);

  const [isChecked, setIsChecked] = useState(null);

  const handleSwitchChange = async () => {
    setIsChecked(!isChecked);
    if (!isChecked) {
      let params = {
        "env": "live"
      }
      await switchEnvironment(params)
    } else {
      let params = {
        "env": "test"
      }
      await switchEnvironment(params)

    }

  };


  const [emailAddress, setEmailAddress] = useState('');
  const [url, setUrl] = useState('');
  const [testCustomApi, setTestCustomApi] = useState('');
  const [liveCustomApi, setLiveCustomApi] = useState('');

  // const [env, setEnv] = useState('');
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!emailAddress.trim()) {
      newErrors.emailAddress = 'Email address is required';
    } else if (!emailAddress.match(regex)) {
      newErrors.emailAddress = 'Email address is not valid';
    }

    if (!url.trim()) {
      newErrors.url = 'url is required';
    }

    return newErrors;
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    let params = {
      "redirectUrl": url,
      "notifyEmail": emailAddress,
      "liveCustomApi": liveCustomApi,
      "testCustomerApi": testCustomApi
    };
    addSettingRecords(params);
  }

  useEffect(() => {
    if (parsedUser === null) {
      window.location.href = '/';
    }

    fetchSettingRecords();
  }, []);

  useEffect(() => {

    if (settingRecords !== null) {
      setEmailAddress(settingRecords?.data?.notifyEmail)
      setUrl(settingRecords?.data?.redirectUrl)

      setTestCustomApi(settingRecords?.data?.testCustomerApi)
      setLiveCustomApi(settingRecords?.data?.liveCustomApi)

      if (settingRecords?.env === 'test') {
        setIsChecked(false);
      } else {
        setIsChecked(true);
      }
    }

  }, [settingRecords]);

  return (
    <>
      <h4 className="mt-4">Settings</h4>
      <Row>
        <Col xs={12} className="">
          <Card>
            <Card.Body>
              {fetchSettingRecordsLoading ? <h1 className="text-center">Loading...</h1> :
                <form >
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group id="notifyEmail">
                        <Form.Label>Notify Email</Form.Label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="example@company.com"
                          value={emailAddress}
                          onChange={(e) => setEmailAddress(e.target.value)}
                        />
                        {errors.emailAddress && <span className="text-danger">{errors.emailAddress}</span>}
                      </Form.Group>
                    </Col>

                    <Col md={6} className="mb-3">
                      <Form.Group id="testCustomApi">

                        <Form.Label>Test Custom Api</Form.Label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Please enter your custom API (test)"
                          value={testCustomApi}
                          onChange={(e) => setTestCustomApi(e.target.value)}
                        />
                        {errors.url && <span className="text-danger">{errors.url}</span>}
                      </Form.Group>
                    </Col>

                    <Col md={6} className="mb-3">
                      <Form.Group id="redirectUrl">

                        <Form.Label>Redirect Url</Form.Label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="www.example.com"
                          value={url}
                          onChange={(e) => setUrl(e.target.value)}
                        />
                        {errors.url && <span className="text-danger">{errors.url}</span>}
                      </Form.Group>
                    </Col>


                    <Col md={6} className="mb-3">
                      <Form.Group id="liveCustomApi">

                        <Form.Label>Live Custom Api</Form.Label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Please enter your custom API (live)"
                          value={liveCustomApi}
                          onChange={(e) => setLiveCustomApi(e.target.value)}
                        />
                        {errors.url && <span className="text-danger">{errors.url}</span>}
                      </Form.Group>
                    </Col>


                    <Col md={6} className="mb-3">
                      <Form.Group id="env">
                        {switchEnvironmentLoading ? <h6>Loading...</h6> : <Form.Label>Current Environment: <span className="fw-bold text-upper">{settingRecords?.env}</span></Form.Label>}
                        <Form.Check
                          type="switch"
                          id="env"
                          label="Environment"
                          checked={isChecked}
                          onChange={handleSwitchChange}
                        />
                      </Form.Group>
                    </Col>


                    <Col md={6} className="mb-3">
                      <Form.Group id="env">
                        {/* {switchEnvironmentLoading?<h6>Loading...</h6>:<Form.Label>Current Environment: <span className="fw-bold text-upper">{settingRecords?.env}</span></Form.Label>}
                        <Form.Check
                          type="switch"
                          id="env"
                          label="Environment"
                          checked={isChecked}
                          onChange={handleSwitchChange}
                        /> */}
                        Live Key:
                        <Breadcrumb listProps={{ className: "breadcrumb-tertiary breadcrumb-text-light text-white" }}>

                          <Breadcrumb.Item href="#LiveKey">{settingRecords?.data?.liveMerchantKey}</Breadcrumb.Item>
                        </Breadcrumb>

                        Test Key:
                        <Breadcrumb listProps={{ className: "breadcrumb-secondary breadcrumb-text-light text-white" }}>

                          <Breadcrumb.Item href="#TestKey">{settingRecords?.data?.testMerchantKey}</Breadcrumb.Item>
                        </Breadcrumb>
                      </Form.Group>
                    </Col>



                    <Col md={3} className="mb-3 mt-3">
                      <Button className="w-50" variant="primary" type="submit" onClick={onSubmit} >{addRecordsLoading ? 'Loading...' : 'Save'}</Button>
                    </Col>
                  </Row>

                </form>}

            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>)
};
