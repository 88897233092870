import React, { useContext, useEffect } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons';
import { Col, Row, Card, Table } from '@themesberg/react-bootstrap';
import { InsuranceContext } from '../../context/InsuranceContext';
import { formatNaira,formatDate } from '../../utils/utils'

const SubscriptionDetails = () => {
    const history = useHistory();
    const search = useLocation().search
    const searchParams = new URLSearchParams(search)
    const { parsedUser, subscriptions, subscriptionsLoading, fetchSubscriptions, } = useContext(InsuranceContext);
    const queryId = searchParams.get('id').toString();
    const subscription = subscriptions?.data?.data.filter((item) => item.id.toString() === queryId);

    useEffect(() => {
        if (parsedUser === null) {
            window.location.href = '/';
          }
        fetchSubscriptions();
    }, []);

    return (
        <>
            <h5 className="mb-4 mt-4"> <FontAwesomeIcon icon={faArrowAltCircleLeft} className="me-2" onClick={() => history.push('/subscription')} /> Credit Check (Transactions)</h5>
            <p className="mb-lg-5 mb-4">Customer’s credit check details</p>
            <Row className="mt-lg-5 mt-4 d-flex">
                {
                    ((typeof subscriptions === "undefined") || (typeof subscription === "undefined")) ? <>Loading...</> :
                        <Col xl={8}>
                            <Card>
                                <Table className="comparison-table table-striped">
                                    <thead className="thead-light">
                                        <tr>
                                            <th className="border-0"><h6 className="fw-bolder">Details</h6></th>
                                            <th className="border-0 fw-bolder"><h6 className="fw-bolder"></h6></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="border-0">Full Name</td>
                                            <td className="border-0">{subscription[0]?.customer.first_name || 'N/A'}</td>
                                        </tr>

                                        <tr>
                                            <td className="border-0">Full Name</td>
                                            <td className="border-0">{subscription[0]?.customer.first_name || 'N/A'} {subscription[0]?.customer.last_name}</td>
                                        </tr>
                                        <tr>
                                            <td className="border-0">Email Address</td>
                                            <td className="border-0">{subscription[0]?.customer.email || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td className="border-0">Request</td>
                                            <td className="border-0">Credit Check</td>
                                        </tr>
                                        <tr>
                                            <td className="border-0">Insurance Company</td>
                                            <td className="border-0">Leadway</td>
                                        </tr>
                                        <tr>
                                            <td className="border-0">Insurance Type</td>
                                            <td className="border-0">Comprehensive</td>
                                        </tr>
                                        <tr>
                                            <td className="border-0">Phone Number</td>
                                            <td className="border-0">{subscription[0]?.customer.phone || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td className="border-0">Load Amount</td>
                                            <td className="border-0">{formatNaira(subscription[0]?.customer.amount)}</td>
                                        </tr>
                                        <tr>
                                            <td className="border-0">Transaction Date</td>
                                            <td className="border-0">{formatDate(subscription[0]?.createdAt)}</td>
                                        </tr>                             <tr>
                                            <td className="border-0">Repayment Plan</td>
                                            <td className="border-0">{subscription[0]?.plan.interval}</td>
                                        </tr>  <tr>
                                            <td className="border-0">Credit Check Status</td>
                                            <td className="border-0">{subscription[0]?.customer.status || 'N/A'}</td>

                                        </tr>
                                    </tbody>
                                </Table>
                            </Card>
                        </Col>
                }


            </Row>
        </>
    )
}

export default SubscriptionDetails