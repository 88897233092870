
import React, { useContext, useEffect, useState } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { Nav, Badge, Image, Button, Navbar } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { Routes } from "../routes";
import { InsuranceContext } from '../context/InsuranceContext';
import OctamileLogoBlue from "./octamile_logo_blue.png";

export default (props = {}) => {

  const { settingRecords, fetchSettingRecords, parsedUser, } = useContext(InsuranceContext);
  let user = JSON.parse(sessionStorage.getItem('user'));

  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";

  const onCollapse = () => setShow(!show);

  const NavItem = (props) => {
    const { title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary" } = props;
    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
    // console.log('link = ' + link)
    // console.log('pathname = ' + pathname)
    // const navItemClassName = link === pathname ? "active" : "";
    // string.includes(substring)
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? <span className="sidebar-icon">{icon()} </span> : null}
            {image ? <Image src={`${process.env.REACT_APP_LOGO_URL}/${user?.logo}`} id="logo" className="sidebar-icon  svg-icon" /> : null}

            {/* {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null} */}
            {/* <span className="sidebar-text">{title}</span> */}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };


  useEffect(() => {
    if (parsedUser === null) {
      window.location.href = '/';
    }

    fetchSettingRecords();
  }, []);

  return (
    <>
      <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
        <Navbar.Brand className="me-lg-5" as={Link} to={Routes.DashboardOverview.path}>
        </Navbar.Brand>
        <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
          <div className="sidebar-inner px-4 pt-3">
            <Nav className="flex-column pt-3 pt-md-0">
              <NavItem link={Routes.Presentation.path} image={OctamileLogoBlue} />
              <br />
              <span>
                {settingRecords?.env === 'test' ? <Badge bg="warning" text="white" className="badge-lg me-1" pill={true}>TEST</Badge> :
                  <Badge bg="success" text="white" className="badge-lg me-1" pill={true}>LIVE</Badge>
                }</span>
              <br />
              <NavItem title="Overview" link={Routes.DashboardOverview.path} />
              <NavItem title="Transactions" link={Routes.Transactions.path} />
              <NavItem title="New Request" link={Routes.Settings.path} />
              <NavItem title="Initiate Transaction" link={Routes.InitiateTransaction.path} />
              <NavItem title="Settings" link={Routes.DocsOverview.path} />
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
