import React, { useContext, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Badge, Row } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { InsuranceContext } from '../../context/InsuranceContext';
import { formatDate, badgeStyle } from '../../utils/utils';

const TransactionsTable = ({ searchTerm }) => {
  const { parsedUser, fetchTransactions, transactionsLoading, transactions } = useContext(InsuranceContext);

  const columns = [
    {
      name: 'Full Name',
      selector: (row) => <span className="text-capitalize">{row?.customers?.firstName}&nbsp;{row?.customers?.lastName}</span>,
    },
    {
      name: 'Phone Number',
      selector: (row) => <>{row?.customers?.phoneNumber}</>,
    },
    {
      name: 'Vehicle',
      selector: (row) => <span className="text-uppercase">{`${row?.customers?.make} ${row?.customers?.model} (${row?.customers?.year})`}</span>,
    },
    {
      name: 'Payment Status',
      selector: (row) => <Badge className="badge-lg text-uppercase" style={badgeStyle(row?.status)}>{row?.status}</Badge>,
    },
    {
      name: 'Transaction Date',
      selector: (row) => <>{formatDate(row.created_at)}</>,
    },
    {
      name: 'Action',
      selector: (row) => <Link className="fw-bold see-more-bold" to={`/request/details?id=${row.transId}`}>See more</Link>,
    },
  ];

  useEffect(() => {
    if (parsedUser === null) {
      window.location.href = '/';
    }
    fetchTransactions();
  }, []);

  let filData = []

  if (typeof transactions !== 'undefined' && typeof transactions !== null && typeof searchTerm !== 'undefined') {
    // && transactions?.data.length !== 0
    if (typeof transactions?.data !== 'undefined') {
      if (searchTerm.trim() !== "") {
        const filteredData = transactions?.data?.filter(item =>
          item.customers.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.customers.phoneNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.customers.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.customers.vehicleType.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.merchantName.toLowerCase().includes(searchTerm.toLowerCase())
        );
        filData = filteredData
      } else {
        filData = transactions?.data;
      }

    }
  }





  return (
    <>
      <Row className="justify-content-md-center px-2">
        {transactionsLoading ? <h1 className="text-center">Loading...</h1> :
          <DataTable
            columns={columns}
            // data={transactions?.data}
            data={filData}
            pagination
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          />}
      </Row>
    </>
  )
}

export default TransactionsTable

export const arrayToCSV = (objArray) => {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str = `${Object.keys(array[0]).map(value => `"${value}"`).join(",")}` + '\r\n';

  return array.reduce((str, next) => {
    str += `${Object.values(next).map(value => `"${value}"`).join(",")}` + '\r\n';
    return str;
  }, str);
}

export const exportToCSV = (data) => {
  const csvContent = arrayToCSV(data.map(item => {
    const res = { ...item, ...item.customers }
    delete res.id
    delete res.customers
    delete res.customersId
    return res
  }))


  var element = document.createElement('a');
  element.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvContent);
  element.target = '_blank';
  element.download = 'paycover-octamile-export.csv';
  element.click();
}
