export function formatDate(inputDate) {
  const months = [
    "Jan", "Feb", "March", "April", "May", "June",
    "July", "Aug", "Sept", "Oct", "Nov", "Dec"
  ];

  // Step 1: Parse input date string into a Date object
  const dateObject = new Date(inputDate);

  // Step 2: Extract day, month, and year
  const day = dateObject.getDate();
  const month = dateObject.getMonth();
  const year = dateObject.getFullYear();

  // Step 3: Convert the numeric month into the corresponding month name
  const monthName = months[month];

  // Step 4: Append the appropriate suffix to the day
  let daySuffix;
  if (day === 1 || day === 21 || day === 31) {
    daySuffix = "st";
  } else if (day === 2 || day === 22) {
    daySuffix = "nd";
  } else if (day === 3 || day === 23) {
    daySuffix = "rd";
  } else {
    daySuffix = "th";
  }

  // Step 5: Extract hours, minutes, and AM/PM
  let hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  const amOrPm = hours >= 12 ? "pm" : "am";

  // Step 6: Convert to 12-hour format
  hours = hours % 12 || 12;

  // Step 7: Assemble the final formatted date with time in 12-hour format
  const formattedDate = `${day}${daySuffix} ${monthName} ${year} ${hours}:${minutes < 10 ? '0' : ''}${minutes}${amOrPm}`;
  return formattedDate;
}

export function formatNaira(number) {
  if (typeof number !== 'number') return 'N/A';
  const formatter = new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
    minimumFractionDigits: 2,
  });
  return formatter.format(number);
}


export const creditCheck = (value) => {
  switch (value) {
    case 'approved':
      return "Passed";
    case 'successful':
      return "Passed";
    case 'pending':
      return "Pending";

    case 'timeout':
      return 'Failed (Timeout)';

    case 'declined':
      return 'Failed (Decline)';
    case 'abandoned':
      return "Passed";
    default:
      return "N/A";
  }
}

export const transactionStatus = (data) => {
  return data?.customers?.status == "abandoned" ? data?.customers?.status : data?.status
}

export const statusCheck = (value) => {
  switch (value) {
    case 'approved':
      return "Not Successful";
    case 'successful':
      return "Successful";
    case 'pending':
      return "Not Successful";
    case 'timeout':
      return "Not Successful";
    case 'declined':
      return "Not Successful";
    case 'abandoned':
      return "Abandoned";
    default:
      return "N/A";
  }
}

export const statusCheckColor = (value) => {
  switch (value) {
    case 'approved':
      return "warning";
    case 'successful':
      return "success";
    case 'pending':
      return "warning";
    case 'timeout':
      return "danger";
    case 'declined':
      return 'danger';
    case 'abandoned':
      return "danger";
    default:
      return "dark";
  }
}



export const insuranceType = (value) => {
  switch (value) {
    case 'private':
      return "Private";
    case 'third_party':
      return 'Third Party';
    case 'comprehensive':
      return 'Comprehensive';
    default:
      return "N/A";
  }
}



export const badgeStyle = (status) => {
  const badgeColors = {
    "pending": '#FFBE0B',
    "abandoned": '#D22E27',
    "passed": '#0AC293',
    "success": '#0AC293',
    "successful": '#0AC293',
    "danger": '#D22E27',
    'not successful': '#D22E27',
    'failed (timeout)': '#D22E27',
    'failed (decline)': '#D22E27',
  }

  return {
    backgroundColor: badgeColors[status.toLowerCase()] + '26',
    color: badgeColors[status.toLowerCase()]
  }
}

